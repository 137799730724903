/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import NotFound from 'views/NotFound';
import Minimal from 'layouts/Minimal';
import WithLayout from 'WithLayout';
import { graphql } from 'gatsby';

const NotFoundPage = (): JSX.Element => {
  return <WithLayout component={NotFound} layout={Minimal} />;
};

export default NotFoundPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
